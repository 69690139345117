import React from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';
import MEDIA_URL from '../../constants';
const items = [
  {
    src: process.env.PUBLIC_URL + `${MEDIA_URL}/web-images/slider/cricket.jpg`,
  },
  {
    src: process.env.PUBLIC_URL + `${MEDIA_URL}/web-images/slider/football.jpg`,
  },
  {
    src: process.env.PUBLIC_URL + `${MEDIA_URL}/web-images/slider/tennis.jpg`,
  }
];

class Slider extends React.Component {
	constructor(props) {
		super(props);
		this.state = { activeIndex: 0 };
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this);
	}

  componentDidMount = () => {
    this.next();
  };

	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	next() {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}

	render() {
		const { activeIndex } = this.state;

		const slides = items.map((item) => {
			return (
				<CarouselItem
					onExiting={this.onExiting}
					onExited={this.onExited}
					key={item.src}
				>
					<img style={{width: "1743px"}} src={item.src} alt={item.altText} />
					{/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
				</CarouselItem>
			);
		});

		return (
			<div>
				<Carousel
					activeIndex={activeIndex}
					next={this.next}
					previous={this.previous}
				>
					<CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
					{slides}
					{/* <CarouselControl direction='prev' directionText='Previous' onClickHandler={this.previous} />
					<CarouselControl direction='next' directionText='Next' onClickHandler={this.next} /> */}
				</Carousel>
			</div>
		);
	}
}

export default Slider;
